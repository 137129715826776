<script setup lang="ts">
import type { LegendSeries } from '@console/components/charts/ChartLegend.vue';
import type { AwsAdmOfferingCode, BillingOfferingVariant } from '@console/services/api.models';
import type {
  ComputeBaseFlexSpendCoverageTrend,
  ComputeInheritedBaseSmartSpendCoverageTrend,
  ComputeSpendCoverageTrend,
  NonComputeSpendCoverageTrend,
  SpendCoverageTrend,
} from '@console/services/aws/savings.models';
import type { TooltipFormatterContextObject, TooltipOptions } from 'highcharts';

import Big from 'big.js';
import he from 'he';
import Highcharts from 'highcharts';
import moment from 'moment';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import * as chartUtilities from '@console/components/charts/utility';
import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';
import * as DateHelpers from '@shared/utilities/date_helpers';
import NumberHelpers from '@shared/utilities/number_helpers';
import { isTruthy } from '@shared/utilities/typescript_helpers';

import ChartLegend from '@console/components/charts/ChartLegend.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';

const SERIES_NAME_BASE_TARGET = 'Base Target';

type Series = Highcharts.SeriesOptions & LegendSeries;

type SeriesColors = Partial<Record<keyof SpendCoverageTrend, string>>;
const seriesColors: {
  compute: Partial<Record<BillingOfferingVariant, SeriesColors>>;
  nonCompute: SeriesColors;
} = {
  compute: {
    AwsComputeBaseFlex: {
      capacity_block_reservation_usage: '#fecbcb',
      on_demand: '#fc5454',
      spot: '#fcbe2c',
      flex: '#00c58c',
      flex_boost: '#8fffdf',
      base_convertible_reserved_instances: '#a7a3ff',
      base_standard_reserved_instances: '#d4d1ff',
      base_ec2_instance_savings_plans: '#adb5bd',
      base_compute_savings_plans: '#5c54ff',
      unbilled: '#dee2e6',
      base_spend_coverage_target: '#495057',
    } as Record<keyof ComputeBaseFlexSpendCoverageTrend, string>,
    AwsComputeInheritedBaseSmart: {
      capacity_block_reservation_usage: '#fecbcb',
      on_demand: '#fc5454',
      spot: '#fcbe2c',
      smart_convertible_reserved_instances: '#00c58c',
      smart_standard_reserved_instances: '#05004d',
      smart_compute_savings_plans: '#8fffdf',
      smart_ec2_instance_savings_plans: '#a7a3ff',
      base_compute_savings_plans: '#5c54ff',
      unbilled: '#dee2e6',
      inherited_convertible_reserved_instances: '#adb5bd',
      inherited_standard_reserved_instances: '#d9fff4',
      inherited_compute_savings_plans: '#d4d1ff',
      inherited_ec2_instance_savings_plans: '#feebbf',
      base_spend_coverage_target: '#495057',
    } as Record<keyof ComputeInheritedBaseSmartSpendCoverageTrend, string>,
  },
  nonCompute: {
    on_demand: '#fc5454',
    smart_reserved_coverage: '#00c58c',
    inherited_reserved_coverage: '#5c54ff',
  } as Record<keyof NonComputeSpendCoverageTrend, string>,
};

const props = defineProps<{
  spendCoverageTrend: SpendCoverageTrend[];
  service: AwsAdmOfferingCode;
  allDiscounts?: boolean;
  emptyYAxisMax?: number;
}>();

const noData = ref(false);
const series = ref([] as Series[]);

const isProductCompute = computed(() => props.service === 'compute');
const xAxis = computed(() => ({
  categories: props.spendCoverageTrend.map(sc => moment.utc(sc.month_start).format('MMM YYYY')),
}));

const yAxis = computed<Highcharts.YAxisOptions[]>(() => [
  {
    min: 0,
    max: noData.value ? props.emptyYAxisMax : undefined,
    title: {
      text: null,
    },
    labels: {
      format: chartUtilities.getCurrencyFormat(),
    },
  },
]);

const filteredSeries = computed(() => series.value.filter(s => s.legendSelected));
const serviceDisplayName = computed(() => AwsServiceHelpers.getDisplayName(props.service));
const tooltip = computed<TooltipOptions>(() => {
  return {
    ...chartUtilities.defaultTooltip,
    formatter: function () {
      const categoryIndex = xAxis.value.categories.indexOf(this.x as string);
      const month = months.value[categoryIndex];
      const points = this.points ?? [];
      const xLabel = this.x?.toString() ?? '';

      const excludedPoints = points.filter(r => r.series.name === SERIES_NAME_BASE_TARGET);
      const includedPoints = points.filter(r => r.series.name !== SERIES_NAME_BASE_TARGET);
      const totalValue = Number(includedPoints.reduce((sum, p) => sum.add(p.y ?? 0), Big(0)));
      const excludedRowsHtml = excludedPoints.map(p => pointToHtml(p, month)).join('');
      const includedRowsHtml = includedPoints.map(p => pointToHtml(p, month)).join('');

      let totalHtml = '';
      if (includedPoints.length >= 2) {
        totalHtml = `
          <div style="padding-top: 2px">
            ${chartUtilities.encodedTooltipRow(
              `${serviceDisplayName.value} Usage`,
              totalValue,
              NumberHelpers.formatDollars
            )}
          </div>
          `;
      }

      let overallCoverageHtml = '';
      if (month.overall_spend_coverage_percentage) {
        overallCoverageHtml = chartUtilities.encodedTooltipRow(
          'Overall Spend Coverage',
          month.overall_spend_coverage_percentage,
          v => NumberHelpers.formatNumber(v, 1) + '%'
        );
      }

      let activeOrgsHtml = '';
      if (month.active_organizations && month.active_organizations > 0) {
        activeOrgsHtml = chartUtilities.encodedTooltipRow('Active Organizations', month.active_organizations);
      }

      return `
            <div>
              <div style="font-size: 12px;">
                <strong>${he.encode(xLabel)}</strong>
              </div>
              ${excludedRowsHtml /* excluded rows are shown first */}
              ${includedRowsHtml}
              ${totalHtml}
              ${overallCoverageHtml}
              ${activeOrgsHtml}
            </div>
          `;
    },
  };
});

const pointToHtml = (point: TooltipFormatterContextObject, month: SpendCoverageTrend) => {
  let percentage: number | undefined;
  var seriesDef = isProductCompute.value
    ? computeSeriesDefs.value.find(s => s && s.name === point.series.name)
    : nonComputeSeriesDefs.find(s => s.name === point.series.name);
  if (seriesDef) {
    const percentageField = seriesDef.field + '_percentage';
    if (percentageField in month) {
      percentage = month[percentageField as keyof SpendCoverageTrend] as number;
    }
  }

  // Use either the color code or the pattern's color code (if a pattern is being used). Don't bother rendering the
  // actual pattern, since the tooltip's series color circle is too small.
  let color = point.color;
  if (color && typeof color !== 'string') {
    color = 'pattern' in color ? color.pattern?.color : undefined;
  }

  return chartUtilities.encodedTooltipRow(point.series.name, point.y, NumberHelpers.formatDollars, color, percentage);
};

interface SeriesDef {
  name: string;
  type: 'area' | 'line';
}

// eslint-disable-next-line no-undef
type PercentageFields = keyof ExtractFieldsBySuffix<ComputeSpendCoverageTrend, '_percentage'>;
interface ComputeSeriesDef extends SeriesDef {
  // false positive, defined in utility.d.ts
  // eslint-disable-next-line no-undef
  field: keyof OptionalNumericFields<Omit<ComputeSpendCoverageTrend, PercentageFields>>;
}

interface NonComputeSeriesDef extends SeriesDef {
  // false positive, defined in utility.d.ts
  // eslint-disable-next-line no-undef
  field: keyof OptionalNumericFields<NonComputeSpendCoverageTrend>;
}

// This is the order the items will be stacked in the chart (the first will be on the top)
const computeSeriesDefs = computed<(ComputeSeriesDef | false)[]>(() => [
  props.allDiscounts && {
    name: 'Capacity Block Reservation',
    field: 'capacity_block_reservation_usage',
    type: 'area',
  },
  { name: 'On-Demand', field: 'on_demand', type: 'area' },
  props.allDiscounts && { name: 'Spot', field: 'spot', type: 'area' },
  { name: 'Smart: Convertible RIs', field: 'smart_convertible_reserved_instances', type: 'area' },
  { name: 'Smart: Standard RIs', field: 'smart_standard_reserved_instances', type: 'area' },
  { name: 'Smart: Compute SPs', field: 'smart_compute_savings_plans', type: 'area' },
  { name: 'Smart: EC2 Instance SPs', field: 'smart_ec2_instance_savings_plans', type: 'area' },
  { name: 'Flex', field: 'flex', type: 'area' },
  { name: 'Flex Boost', field: 'flex_boost', type: 'area' },
  { name: 'Base: Convertible RIs', field: 'base_convertible_reserved_instances', type: 'area' },
  { name: 'Base: Standard RIs', field: 'base_standard_reserved_instances', type: 'area' },
  { name: 'Base: EC2 Instance SPs', field: 'base_ec2_instance_savings_plans', type: 'area' },
  { name: 'Base: Compute SPs', field: 'base_compute_savings_plans', type: 'area' },
  { name: 'Unbilled', field: 'unbilled', type: 'area' },
  { name: 'Inherited: Convertible RIs', field: 'inherited_convertible_reserved_instances', type: 'area' },
  { name: 'Inherited: Standard RIs', field: 'inherited_standard_reserved_instances', type: 'area' },
  { name: 'Inherited: Compute SPs', field: 'inherited_compute_savings_plans', type: 'area' },
  { name: 'Inherited: EC2 Instance SPs', field: 'inherited_ec2_instance_savings_plans', type: 'area' },
  { name: SERIES_NAME_BASE_TARGET, field: 'base_spend_coverage_target', type: 'line' },
]);

// This is the order the items will be stacked in the chart (the first will be on the top)
const nonComputeSeriesDefs: NonComputeSeriesDef[] = [
  { name: 'On-Demand', field: 'on_demand', type: 'area' },
  { name: 'Smart', field: 'smart_reserved_coverage', type: 'area' },
  { name: 'Inherited', field: 'inherited_reserved_coverage', type: 'area' },
];

const months = computed(() => {
  const firstMonths = props.spendCoverageTrend.slice(0, -1);
  const lastMonth = props.spendCoverageTrend.slice(-1)[0] ?? {};
  // Don't include the last month if there aren't any fields other than month_start (i.e. no spend coverage data for the month)
  const includeLastMonth = Object.keys(lastMonth).some(key => key !== 'month_start');
  return includeLastMonth ? [...firstMonths, lastMonth] : firstMonths;
});

// TODO this is temporarily to allow testing since we don't have any customers have have switch billing offering variants yet
const route = useRoute();
const featureFlagMonths = computed(() => {
  if (!route.query.billing_model_transition_month) return undefined;
  if (months.value.length === 0) return undefined;

  const newMonths = months.value.map(m => ({ ...m })) as SpendCoverageTrend[];

  const transitionMonths = Array.isArray(route.query.billing_model_transition_month)
    ? route.query.billing_model_transition_month
    : [route.query.billing_model_transition_month];

  transitionMonths.forEach((transitionMonth, i) => {
    const variant = i % 2 === 0 ? 'AwsComputeInheritedBaseSmart' : 'AwsComputeBaseFlex';
    const nextMonthStart = transitionMonths[i + 1] ?? '2100-01-01T00:00:00Z';

    newMonths.forEach(m => {
      if (moment.utc(m.month_start).isBetween(transitionMonth, nextMonthStart, 'day', '[)')) {
        m.variant = variant;

        // If converting Base/Flex to Inherited/Base/Smart, translate the Base/Flex fields to the new variant
        if (variant === 'AwsComputeInheritedBaseSmart') {
          m.smart_convertible_reserved_instances = m.flex;
          m.smart_convertible_reserved_instances_percentage = m.flex_percentage;
          m.smart_standard_reserved_instances = m.flex_boost;
          m.smart_standard_reserved_instances_percentage = m.flex_boost_percentage;
          m.inherited_convertible_reserved_instances = m.base_convertible_reserved_instances;
          m.inherited_convertible_reserved_instances_percentage = m.base_convertible_reserved_instances_percentage;
          m.inherited_standard_reserved_instances = m.base_standard_reserved_instances;
          m.inherited_standard_reserved_instances_percentage = m.base_standard_reserved_instances_percentage;
          m.inherited_ec2_instance_savings_plans = m.base_ec2_instance_savings_plans;
          m.inherited_ec2_instance_savings_plans_percentage = m.base_ec2_instance_savings_plans_percentage;
        }
      }
    });
  });

  return newMonths;
});

// If a customer has switched from one billing offering variant (e.g. Base/Flex) to another (e.g. Inherited/Base/Smart),
// the available series for each variant are different, with the possibility of overlap (e.g. On-Demand is the same,
// but Base/Flex has Flex Boost whereas Inherited/Base/Smart has Smart SRIs) and uses different colors. To address this,
// create separate sets of series for each variant so that they can be rendered with a gap between them. Additionally,
// the older variant's series will be rendered with a fill pattern to further visually differentiate them.
const variantMonths = computed(() =>
  (featureFlagMonths.value ?? months.value)
    .reduce((results, month) => {
      const currentRecord = results.at(-1);
      if (!currentRecord || currentRecord?.variant !== month.variant) {
        results.push({
          variant: month.variant,
          start: month.month_start,
          end: month.month_start,
        });
      } else {
        currentRecord.end = month.month_start;
      }

      return results;
    }, [] as Array<{ variant?: BillingOfferingVariant; start: string; end: string }>)
    // Put them in reverse order, so that the most recent record is first
    .reverse()
    .map((r, index) => ({
      ...r,
      title:
        index === 0
          ? 'Current Billing Model'
          : `Previous Billing Model (ended ${DateHelpers.formatDateMonthAndYearOnly(r.end)})`,
    }))
);

onMounted(() => {
  const seriesDefs = (isProductCompute.value ? computeSeriesDefs.value : nonComputeSeriesDefs).filter(isTruthy);

  const areaOptions = {
    fillOpacity: '0.5',
    dashStyle: 'solid',
    stacking: 'normal',
  };
  const lineOptions = {
    dashStyle: 'dash',
  };

  const mappedSeries = variantMonths.value.flatMap((variantMonth, variantIndex) => {
    const variant = variantMonth.variant;

    return seriesDefs.map((s, seriesIndex) => {
      const data = (featureFlagMonths.value ?? months.value).map(month => {
        // Treat any any months that don't match the variant & month range as null so that only the points that apply
        // are plotted for this variant's version of the series
        const matchesVariant = month.variant === variant;
        const isInRange = month.month_start >= variantMonth.start && month.month_start <= variantMonth.end;
        return matchesVariant && isInRange ? month[s.field] ?? null : null;
      });

      const color = getSeriesColor(s.field, variant);
      const legendOrderVariantOffset = 100 * variantIndex;

      return {
        groupTitle: variantMonth.title,
        label: s.name,
        color: getColorOrPatternForVariantSeries(variantIndex, color),
        // Use null as the default value to ensure that days without values are still shown on the x axis
        data,
        // Reverse the order of the legend so that the last item (bottom) is the leftmost
        legendOrder: legendOrderVariantOffset + seriesDefs.length - seriesIndex,
        legendSelected: true,
        tooltip: {
          valuePrefix: '$',
        },
        marker: {
          enabled: chartUtilities.hasSingleDataPoint(data),
          // For old billing offering variant series that are rendered with a pattern, use the pattern's color, but
          // don't render the marker using the pattern (which is too small to see)
          fillColor: color,
        },
        // Apply the options specific to the series type
        ...(s.type === 'area' ? areaOptions : lineOptions),
        // Allow the series to override any of the above properties
        ...s,
      };
    });
  });

  series.value = mappedSeries
    // Don't include any series without a color (i.e. series belongs to another service or variant)
    .filter(s => !!s.color)
    // Remove any series without any data points
    .filter(s => s.data.some(d => !!d));

  // We don't re-capture historical stats for the all discounts view, force the chart to draw
  if (props.emptyYAxisMax && series.value.length === 0) {
    noData.value = true;
    series.value = [
      {
        type: 'area',
        data: xAxis.value.categories.map(() => null),
        legendOrder: 1,
        legendSelected: true,
      } as Series,
    ];
  }
});

function getSeriesColor(field: keyof SpendCoverageTrend, variant?: BillingOfferingVariant) {
  const colors = isProductCompute.value ? seriesColors.compute[variant!] ?? {} : seriesColors.nonCompute;
  return colors[field];
}

function getColorOrPatternForVariantSeries(variantIndex: number, color?: string) {
  // If this is the first variant, use a solid fill instead of a pattern
  if (variantIndex === 0) return color;

  // If a color wasn't found, don't try to render a pattern
  if (!color) return undefined;

  return {
    pattern: {
      ...Highcharts.patterns[variantIndex - 1],
      color,
    },
  };
}
</script>

<template>
  <div>
    <ChartLegend v-model="series" />
    <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :series="filteredSeries" :tooltip="tooltip" />
    <div class="d-flex flex-row-reverse">
      <small>(normalized)</small>
    </div>
  </div>
</template>
