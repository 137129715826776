<script lang="ts" setup>
import type { PatternObject, PatternOptionsObject } from 'highcharts';

import _ from 'lodash';
import { computed } from 'vue';

export interface LegendSeries {
  /** If provided and there's more than 1, the legends are grouped and listed under this heading */
  groupTitle?: string;

  label: string;
  legendOrder: number;
  legendSelected: boolean;
  data: (number | null | undefined)[];
  color?: string | PatternObject;
}

const model = defineModel<LegendSeries[] | undefined>({ required: true });

interface EnhancedLegendSeries extends LegendSeries {
  // Keep a reference to the original item so that updating it causes the v-model update event to fire
  originalItem: LegendSeries;

  notClickable: boolean;
  color?: string;
  pattern?: PatternOptionsObject;
}

interface LegendSeriesGroup {
  title?: string;
  items: EnhancedLegendSeries[];
}

const groupedItems = computed(() => {
  // Remove any series without any data points
  let items = model.value?.filter(item => item.data.some(_.isNumber)) ?? [];
  items = _.sortBy(items, 'legendOrder');

  const countSelected = items.filter(i => i.legendSelected).length;

  return items.reduce((groups, item) => {
    let currentGroup = groups.find(g => g.title === item.groupTitle);
    if (!currentGroup) {
      currentGroup = { title: item.groupTitle, items: [] };
      groups.push(currentGroup);
    }

    currentGroup.items.push({
      ...item,
      originalItem: item,
      // The item is not clickable if it's the last selected item
      notClickable: countSelected === 1 && item.legendSelected,
      color: typeof item.color === 'string' ? item.color : undefined,
      pattern: typeof item.color === 'object' && 'pattern' in item.color ? item.color?.pattern : undefined,
    });

    return groups;
  }, [] as LegendSeriesGroup[]);
});

const hasMultipleGroups = computed(() => groupedItems.value.length > 1);

function click(item: EnhancedLegendSeries) {
  if (item.notClickable) return;

  item.originalItem.legendSelected = !item.originalItem.legendSelected;

  // Update the model value to trigger v-model update (this doesn't appear to be needed for the browser, but was needed
  // for the tests)
  model.value = [...(model.value ?? [])];
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false, // Prevent attributes from assignment to root
  compatConfig: {
    // Needed to allow use of v-model instead of v-model:model-value in compatibility mode
    // See: https://v3-migration.vuejs.org/breaking-changes/v-model
    MODE: 2,
    COMPONENT_V_MODEL: false,
  },
};
</script>

<template>
  <div class="legend">
    <div v-for="(group, index) in groupedItems" :key="group.title" class="legendGroup" :class="{ 'mt-3': index > 0 }">
      <div v-if="hasMultipleGroups">
        <div class="legendGroupTitle mb-1">{{ group.title }}</div>
      </div>
      <div class="legendSeries">
        <div
          v-for="(item, i) in group.items"
          :key="item.label"
          class="legendItem"
          :class="{ notSelected: !item.legendSelected, notClickable: item.notClickable }"
          @click="click(item)"
        >
          <svg v-if="item.pattern" width="20" height="10">
            <defs>
              <pattern
                :id="`pattern-${i}`"
                patternUnits="userSpaceOnUse"
                patternContentUnits="userSpaceOnUse"
                :width="item.pattern.width"
                :height="item.pattern.height"
                :patternTransform="item.pattern.patternTransform"
                x="0"
                y="0"
              >
                <path :d="item.pattern.path as string" :stroke="item.pattern.color" stroke-width="2" fill="none" />
              </pattern>
            </defs>
            <rect width="20" height="10" rx="5" ry="5" :fill="`url(#pattern-${i})`" />
          </svg>
          <div v-else class="bullet" :style="{ backgroundColor: item.color }"></div>
          <div class="chartLegendText">{{ item.label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.legend {
  margin-bottom: 2rem;
}

.legendSeries {
  display: flex;
  flex-wrap: wrap;
}

.legendGroupTitle {
  font-weight: 500;
}

.legendItem {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  margin-right: 1.2rem;
  cursor: pointer;
}

.legendItem .bullet {
  width: 20px;
  height: 10px;
  border-radius: 5px;
}

.legendItem div .chartLegendText {
  font-size: 0.8rem;
}

.notClickable {
  cursor: not-allowed;
}

.notSelected {
  opacity: 0.5;
}

.chartLegendText {
  max-width: 275px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include media-breakpoint-up(md) {
    max-width: 100%;
  }
}
</style>
